<template>
  <div>
    <vs-navbar
      type="gradient"
      id="navbar"
      v-model="activeItem"
      class="justify-start items-center py-2"
    >
      <vs-navbar-item index="0">
        <router-link
          class="button flex items-center justify-center"
          to="/new-dashboard"
        >
          <feather-icon
            icon="HomeIcon"
            class="mr-2"
            svgClasses="h-5 w-5"
          ></feather-icon>
          Home
        </router-link>
      </vs-navbar-item>
      <vs-navbar-item index="1">
        <router-link
          class="button flex items-center justify-center"
          to="/new-dashboard/marketing"
        >
          <feather-icon
            icon="BarChartIcon"
            class="mr-2"
            svgClasses="h-5 w-5"
          ></feather-icon>
          Marketing
        </router-link>
      </vs-navbar-item>
      <vs-navbar-item index="2">
        <router-link
          class="button flex items-center justify-center"
          to="/new-dashboard/performance"
        >
          <feather-icon
            icon="TrendingUpIcon"
            class="mr-2"
            svgClasses="h-5 w-5"
          ></feather-icon>
          Performance
        </router-link>
      </vs-navbar-item>
    </vs-navbar>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeItem: 0,
    };
  },
  mounted() {
    if (this.$route.path.includes("marketing")) this.activeItem = 1;
    if (this.$route.path.includes("performance")) this.activeItem = 2;
  },
};
</script>

<style lang="scss" scoped>
.button {
  @apply w-32 h-10;
}
#navbar {
  z-index: 1;
  box-shadow: none;
}
</style>
